/**
 * Created by Mauritz Untamala on 24/09/15.
 */
import * as React from 'react';
import {Component} from 'react';
import I18n from './I18n';
import {Nav, Navbar, NavItem} from 'react-bootstrap';
import {navigate} from '../modules/Location';
import AuthenticatedUser from '../modules/AuthenticatedUser';
import {connect} from 'react-redux';
import Permission from '../services/Permission';

const logo = require('../images/intra-logo.svg');

const navItems = [{
  label: 'hour-report',
  href: '/hour-report',
  permission: Permission.ReadHourReport
}, {
  label: 'customers',
  href: '/customers',
  permission: Permission.ReadCustomer
}, {
  label: 'projects',
  href: '/projects',
  permission: Permission.ReadProject
}, {
  label: 'users',
  href: '/users',
  permission: Permission.ReadUser
}, {
  label: 'profile',
  href: '/profile',
  permission: Permission.ReadUserProfile
}, {
  label: 'report',
  href: '/report',
  permission: Permission.ReadReport
}, {
  label: 'tasks',
  href: '/tasks',
  permission: Permission.WriteTask
}];

const mapStateToProps = ({authenticatedUser}, _ownProps) => ({authenticatedUser});

const mapActionsToProps = {navigate, logout: AuthenticatedUser.logout};

class NavigationView extends Component<any, any> {

  constructor(props) {
    super(props);
  }

  onLinkClick = (href) => this.props.navigate(href);

  createNavItem = (navItem) => {

    return (
      <NavItem key={'navitem-' + navItem.href}
               active={navItem.href === this.props.location.pathname}
               onSelect={() => this.onLinkClick(navItem.href)}>
        <I18n i18nKey={'navigation.' + navItem.label}/>
      </NavItem>
    );
  };

  filterByPermission = (navItem) => this.props.authenticatedUser.hasPermission(navItem.permission);

  getNavItems = () => navItems.filter(this.filterByPermission).map(this.createNavItem);

  render() {

    return (
      <div key='navigation-view' className='navigation-view'>
        <Navbar key='navbar' fluid={true} fixedTop={true}>
          <Navbar.Header>
            <Navbar.Brand>
              <img className='log' src={logo}/>
            </Navbar.Brand>
            <Navbar.Toggle/>
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              {this.getNavItems()}
            </Nav>
            <Nav pullRight={true}>
              <NavItem key={'navitem-logout'}
                        onSelect={this.props.logout}>
                <i className='fa fa-sign-out'></i>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {this.props.children}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(NavigationView);
